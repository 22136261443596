import {
    ApplicationConfig,
    provideZoneChangeDetection,
    inject,
    provideAppInitializer,
} from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideIonicAngular } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import {
    eye,
    eyeOff,
    language,
    informationCircle,
    alertCircle,
    checkmarkCircle,
    warning,
    ellipsisHorizontalCircle,
    checkmarkCircleOutline,
} from 'ionicons/icons';
import { provideHttpClient } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideOAuthClient } from 'angular-oauth2-oidc';
import { APP_BASE_HREF } from '@angular/common';
import { LanguageService } from './core/services/language.service';
import { AccessTokenService } from './core/services/accessTokenService.service';
import { IdentityService } from './core/services/identityService.service';
import { UserService } from './core/services/userService.service';
import { MarppAccessService } from './core/services/marppAccessService.service';
import { PrincipalAuthService } from './core/services/principal-auth.service';
import { IdentityProfileService } from './core/services/identityProfileService.service';
import { InvitationsService } from './core/services/invitation.service';
import { NotificationsService } from './core/services/notifications.service';
import { authServiceProvider } from './core/services/authentication.service';
import { SamlService } from './core/services/saml.service';

addIcons({
    alertCircle,
    checkmarkCircle,
    ellipsisHorizontalCircle,
    eye,
    eyeOff,
    informationCircle,
    language,
    warning,
    checkmarkCircleOutline,
});

export const appConfig: ApplicationConfig = {
    providers: [
        provideIonicAngular({}),
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideRouter(routes),
        provideHttpClient(),
        provideAnimations(),
        provideOAuthClient(),

        // App Config
        { provide: APP_BASE_HREF, useValue: '/' },
        provideAppInitializer(() => {
            const initializerFn = ((languageService: LanguageService) => async () => {
                return await languageService.initialiseLanguage();
            })(inject(LanguageService));
            return initializerFn();
        }),

        // Services
        authServiceProvider,

        LanguageService,
        AccessTokenService,
        SamlService,
        IdentityService,
        UserService,
        MarppAccessService,
        PrincipalAuthService,
        IdentityProfileService,
        InvitationsService,
        NotificationsService,
    ],
};
