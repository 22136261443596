import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, UrlTree } from '@angular/router';
import { catchError, Observable, of } from 'rxjs';
import { PrincipalAuthService } from '../services/principal-auth.service';
import { AccessTokenService } from '../services/accessTokenService.service';
import { RedirectWhitelistService } from '../services/redirect-whitelist.service';
import { map, switchMap } from 'rxjs/operators';
import { IAccessToken } from '../models/IAccessToken';
import { handleRedirect } from '../utils/routes';

export function samlLoginGuard(): CanActivateFn {
    return (route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> => {
        const accessTokenService = inject(AccessTokenService);
        const principalAuthService = inject(PrincipalAuthService);
        const redirectWhitelistService = inject(RedirectWhitelistService);
        const router = inject(Router);

        const identifier = route.queryParams['identifier'];
        const credentials = route.queryParams['credentials'];
        const redirectUrl = route.queryParamMap.get('redirect');

        if (!identifier || !credentials) {
            return of(true);
        }

        const marcoAuthentication = { identifier, credentials, params: {} };

        return accessTokenService.createAccessToken(marcoAuthentication).pipe(
            switchMap((result: IAccessToken) => {
                principalAuthService.login(result.token);
                if (!redirectUrl) {
                    return of({ valid: false, redirectUrl: undefined });
                }
                return redirectWhitelistService
                    .validateRedirectUrl(redirectUrl)
                    .pipe(map(({ valid }) => ({ valid, redirectUrl })));
            }),
            map(({ valid, redirectUrl }) => {
                handleRedirect(valid ? redirectUrl : undefined);
                return false; // Prevent further navigation, as we've already handled the redirect
            }),
            catchError(() => {
                router.navigate(['auth', 'signing']);
                return of(false);
            }),
        );
    };
}
