import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiClient } from './apiClient.service';
import { IIdentityProfile } from '../models/IIdentityProfile';
import { IImage } from '../models/IImage'; // Define the interface for the image response

// Define the interface for the image response
export interface IImageResponse {
    data: string;
    format: string;
}

@Injectable()
export class IdentityProfileService {
    private apiClient = inject(ApiClient);

    basePath = '/identityProfile';

    public getImage(marcoId: string): Observable<IImageResponse> {
        const path = `${this.basePath}/image/${marcoId}`;
        return this.apiClient.get<IImageResponse>(path);
    }

    public setImage(image: IImage): Observable<void> {
        const path = `${this.basePath}/image`;
        return this.apiClient.post<void>(path, image);
    }

    public updateIdentityProfile(
        marcoId: string,
        identityProfile: IIdentityProfile,
    ): Observable<IIdentityProfile> {
        const path = `${this.basePath}/marcoId/${marcoId}`;
        return this.apiClient.put<IIdentityProfile>(path, identityProfile);
    }
}
