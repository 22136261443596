import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PrincipalAuthService } from '../services/principal-auth.service';
import { RedirectWhitelistService } from '../services/redirect-whitelist.service';
import { handleRedirect } from '../utils/routes';

export function loggedOutGuard(): CanActivateFn {
    return (route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> => {
        const permissionService = inject(PrincipalAuthService);
        const redirectWhitelistService = inject(RedirectWhitelistService);

        if (!permissionService.isLoggedIn()) {
            return of(true);
        }

        const redirectUrl = route.queryParamMap.get('redirect');

        return of(redirectUrl).pipe(
            switchMap((url) => {
                if (!url) {
                    return of({ valid: false, redirectUrl: undefined });
                }
                return redirectWhitelistService
                    .validateRedirectUrl(url)
                    .pipe(map(({ valid }) => ({ valid, redirectUrl: url })));
            }),
            map(({ valid, redirectUrl }) => {
                handleRedirect(valid ? redirectUrl : undefined);
                return false;
            }),
            catchError(() => {
                handleRedirect();
                return of(false);
            }),
        );
    };
}
