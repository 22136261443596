import { Injectable, inject } from '@angular/core';

import { Observable } from 'rxjs';
import { ApiClient } from './apiClient.service';
import { Invitation, InvitationState } from '../models/Invitation';
import { InvitationResponse, ResponseState } from '../models/InvitationResponse';

@Injectable()
export class InvitationsService {
    private apiClient = inject(ApiClient);

    basePath = '/invitation';

    retrieveInvitationByToken(invitationToken: string): Observable<Invitation> {
        const path = `${this.basePath}/${invitationToken}`;
        return this.apiClient.get(path);
    }

    getInvitationDetails(invitationToken: string): Observable<InvitationState> {
        const path = `${this.basePath}/${invitationToken}`;
        return this.apiClient.get(path);
    }

    postInvitationResponse(body: InvitationResponse): Observable<ResponseState> {
        const path = `${this.basePath}/response`;
        return this.apiClient.post(path, body);
    }
}
