import { Injectable, inject } from '@angular/core';

import { ApiClient } from './apiClient.service';
import { Observable } from 'rxjs';
import { IIdentity } from '../models/IIdentity';

@Injectable()
export class IdentityService {
    private apiClient = inject(ApiClient);

    basePath = '/identities';

    public getIdentity(identifier: string): Observable<IIdentity> {
        const identifierEncoded = encodeURIComponent(identifier);
        const path = `${this.basePath}?identifier=${identifierEncoded}`;
        return this.apiClient.get(path);
    }

    public getMyself(): Observable<IIdentity> {
        const path = `${this.basePath}/me`;
        return this.apiClient.get(path);
    }
}
