import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { PrincipalAuthService } from '../services/principal-auth.service';

export function loggedInGuard(): CanActivateFn {
    return (
        route,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
        const permissionService = inject(PrincipalAuthService);
        const router = inject(Router);

        if (!permissionService.isLoggedIn()) {
            void router.navigate(['auth', 'signin'], {
                queryParams: { redirect: route.queryParamMap.get('redirect') },
            });
            return false;
        }

        return true;
    };
}
