import { Injectable } from '@angular/core';
import { loadTranslations } from '@angular/localize';
import { config, SupportedLanguage, Translations } from '../../../../scripts/translation';
import { BehaviorSubject, Observable } from 'rxjs';

export interface LanguageEntry {
    code: SupportedLanguage;
    name: string;
}

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    readonly languages: LanguageEntry[] = Object.entries(config.supportedLanguages).map(
        ([code, name]) => ({
            code: code as SupportedLanguage,
            name,
        }),
    );
    currentLanguage$: Observable<SupportedLanguage>;
    private loadedTranslations: Map<SupportedLanguage, Record<string, string>> = new Map();
    private currentLanguageSubject: BehaviorSubject<SupportedLanguage>;

    constructor() {
        const initialLang = (this.getLocal('app-locale') as SupportedLanguage) || 'en';
        this.currentLanguageSubject = new BehaviorSubject<SupportedLanguage>(initialLang);
        this.currentLanguage$ = this.currentLanguageSubject.asObservable();
    }

    async initialiseLanguage(): Promise<void> {
        const storedLanguage = this.getLocal('app-locale');
        if (!storedLanguage) {
            this.detectBrowserLanguage();
        }
        await this.loadTranslation();
    }

    async loadTranslation(): Promise<void> {
        const storedLanguage = this.getLocal('app-locale')?.toLowerCase() as SupportedLanguage;

        if (storedLanguage && storedLanguage !== 'en') {
            if (this.loadedTranslations.has(storedLanguage)) {
                loadTranslations(this.loadedTranslations.get(storedLanguage)!);
                return;
            }

            try {
                const response = await fetch(`/i18n/translations.json`);
                if (!response.ok) {
                    console.error(`HTTP error ${response.status}`);
                    return;
                }

                const jsonString = await response.text();
                const parsed: Record<string, Translations> = JSON.parse(jsonString);
                const convertedTranslations: Record<string, string> = {};

                for (const [key, value] of Object.entries(parsed)) {
                    if (Object.prototype.hasOwnProperty.call(value.languages, storedLanguage)) {
                        const storedValue = value.languages[storedLanguage];
                        if (storedValue) {
                            convertedTranslations[key] = storedValue;
                        }
                    }
                }
                this.loadedTranslations.set(storedLanguage, convertedTranslations);
                loadTranslations(convertedTranslations);

                console.log(`Translations loaded for ${storedLanguage}`);
            } catch (error) {
                console.error(`Failed to load translations: ${error}`);
            }
        }
    }

    setLanguage(lang: SupportedLanguage): void {
        if (this.isValidLanguage(lang)) {
            this.storeLocal('app-locale', lang);
            this.currentLanguageSubject.next(lang);
            window.location.reload();
        }
    }

    getCurrentLanguage(): SupportedLanguage {
        return this.currentLanguageSubject.value;
    }

    private detectBrowserLanguage(): void {
        const browserLanguage = navigator.language;
        const langCode = browserLanguage.split('-')[0]?.toLowerCase();

        if (langCode && this.isValidLanguage(langCode)) {
            this.storeLocal('app-locale', langCode);
            this.currentLanguageSubject.next(langCode);
        }
    }

    private isValidLanguage(code: SupportedLanguage | string): code is SupportedLanguage {
        return this.languages.some((language) => language.code === code);
    }

    private storeLocal(name: string, value: string): void {
        localStorage.setItem(name, value);
    }

    private getLocal(name: string): string | null {
        return localStorage.getItem(name);
    }
}
