export type Translation = Record<SupportedLanguage, string>;
// Types
export type SupportedLanguage = 'en' | 'es' | 'de' | 'fr' | 'pt' | 'tr' | 'cy';

export interface LanguageGenerated {
    local: string;
    translations: Record<string, string>;
}

export interface Translations {
    source: string;
    languages: Partial<Translation>;
}

// Configuration
export const config = {
    supportedLanguages: {
        en: 'English',
        es: 'Español',
        de: 'Deutsch',
        fr: 'Français',
        pt: 'Português',
        tr: 'Türkçe',
        cy: 'Cymraeg',
    },
    originalFilePath: 'public/i18n/messages.json',
    outputDir: 'public/i18n',
    outputFileName: 'translations',
    firstKeyPrefixes: ['common'],
};
