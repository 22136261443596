import { Injectable, inject } from '@angular/core';
import { ToastController, ToastOptions } from '@ionic/angular';

export type NotificationMessageType = 'error' | 'success' | 'info' | 'warning' | 'neutral';

interface NotificationData {
    message: string;
    description?: string;
    type?: NotificationMessageType;
    icon?: string;

    [key: string]: unknown;
}

@Injectable({
    providedIn: 'root',
})
export class NotificationsService {
    private toastController = inject(ToastController);

    async openFromComponent(
        data: NotificationData,
        options: Partial<ToastOptions> = {},
    ): Promise<void> {
        await this.notify({
            ...options,
            data,
        });
    }

    // Keep the existing methods
    async notifyError(
        message: string,
        additionalData: Record<string, unknown> = {},
    ): Promise<void> {
        await this.notify({ data: { message, type: 'error', ...additionalData } });
    }

    async notifySuccess(
        message: string,
        additionalData: Record<string, unknown> = {},
    ): Promise<void> {
        await this.notify({ data: { message, type: 'success', ...additionalData } });
    }

    async notifyInfo(message: string, additionalData: Record<string, unknown> = {}): Promise<void> {
        await this.notify({ data: { message, type: 'info', ...additionalData } });
    }

    async notifyWarning(
        message: string,
        additionalData: Record<string, unknown> = {},
    ): Promise<void> {
        await this.notify({ data: { message, type: 'warning', ...additionalData } });
    }

    async notifyNeutral(
        message: string,
        additionalData: Record<string, unknown> = {},
    ): Promise<void> {
        await this.notify({ data: { message, type: 'neutral', ...additionalData } });
    }

    private async notify(
        options: Partial<ToastOptions> & { data: NotificationData },
    ): Promise<void> {
        const defaultOptions: ToastOptions = {
            message: options.data.message,
            duration: 5000,
            position: 'top',
            cssClass: `notification top-right ${options.data.type ? `${options.data.type}` : ''}`,
            icon: this.getIconForType(options.data.type),
            buttons: [
                {
                    text: 'X',
                    role: 'cancel',
                },
            ],
        };

        const mergedOptions: ToastOptions = {
            ...defaultOptions,
            ...options,
        };

        if (options.data.description) {
            mergedOptions.header = options.data.message;
            mergedOptions.message = options.data.description;
        }

        const toast = await this.toastController.create(mergedOptions);
        await toast.present();
    }

    private getIconForType(type: NotificationMessageType | undefined): string {
        switch (type) {
            case 'error':
                return 'alert-circle';
            case 'success':
                return 'checkmark-circle';
            case 'info':
                return 'information-circle';
            case 'warning':
                return 'warning';
            case 'neutral':
                return 'ellipsis-horizontal-circle';
            default:
                return 'information-circle';
        }
    }
}
