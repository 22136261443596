import { ActivatedRouteSnapshot, CanActivateFn, Router, UrlTree } from '@angular/router';
import { catchError, Observable, of } from 'rxjs';
import { inject } from '@angular/core';
import { AccessTokenService } from '../services/accessTokenService.service';
import { map } from 'rxjs/operators';
import { buildRedirectUrl } from '../utils/routes';

export function extractQueryParamsGuard(): CanActivateFn {
    return (
        route: ActivatedRouteSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
        const router = inject(Router);
        const accessTokenService = inject(AccessTokenService);

        // Extract query parameters from the active route
        const clientId = route.queryParams['clientId'];
        const redirect = route.queryParams['redirect'];
        const scope = route.queryParams['scope'];

        if (!clientId || !redirect || !scope) {
            void router.navigate(['auth', 'signin']);
            return false;
        }

        return accessTokenService.createAccessTokenForClientId(clientId, scope).pipe(
            map((accessToken) => {
                const token = accessToken.token;
                window.location.href = buildRedirectUrl(redirect, 'GRANTED', token);
                // If the process is successful go to redirect to the requesting app
                return false;
            }),
            catchError(() => {
                // If the process fails go to access page to give consent
                return of(true);
            }),
        );
    };
}
